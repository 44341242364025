import React from "react";
export const AttachmentIcon = ({
                               fill = 'currentColor',
                               filled,
                               size,
                               height,
                               width,
                               label,
                               ...props
                           }) => {
    return (
        <svg
            width={size || width || 28}
            height={size || height || 28}
            viewBox="0 0 20 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 7a5 5 0 0 1 10 0v8a7 7 0 1 1-14 0V9a1 1 0 0 1 2 0v6a5 5 0 0 0 10 0V7a3 3 0 1 0-6 0v8a1 1 0 1 0 2 0V9a1 1 0 1 1 2 0v6a3 3 0 1 1-6 0z"
                fill={fill}
                stroke='none'
            />
        </svg>
    );
};
