import React, { useEffect } from 'react';
import './TransactionDetails.css';
import { HiOutlineX } from "react-icons/hi";

const allTransactions = [
    {
        transactionId: 101,
        orderId: 1,
        paymentMethod: '微信支付',
        platformOrderNum: 123456,
        status: 'Paid',
        createdAt: '2024-07-04',
        updatedAt: '2024-07-05'
    },
    {
        transactionId: 102,
        orderId: 2,
        paymentMethod: '微信支付',
        platformOrderNum: 123457,
        status: 'Paid',
        createdAt: '2024-06-04',
        updatedAt: '2024-06-05'
    },
    {
        transactionId: 103,
        orderId: 3,
        paymentMethod: '微信支付',
        platformOrderNum: 123458,
        status: 'Paid',
        createdAt: '2024-05-04',
        updatedAt: '2024-05-05'
    }
];

const TransactionDetails = ({ transactionId, onClose }) => {
    const transaction = allTransactions.find(trx => trx.transactionId === transactionId);

    useEffect(() => {
        setTimeout(() => {
            document.getElementById("transactionDetailsSidebar").classList.add('open');
        }, 0);
    }, []);

    const handleClose = () => {
        document.getElementById("transactionDetailsSidebar").classList.remove('open');
        setTimeout(onClose, 500);
    };

    if (!transaction) {
        return null;
    }

    return (
        <div id="transactionDetailsSidebar" className="transaction-details">
            <div className="transaction-details-header">
                <button className="close-button" onClick={handleClose}>
                    <HiOutlineX className="icon close-icon" />
                </button>
            </div>
            <div className="transaction-details-content">
                <h2>交易详情</h2>
                <p className="transaction-titles">概要</p>
                <hr className="div-line"/>
                <div className="transaction-overview">
                    <p>订单编号:</p>
                    <p className="data">{transaction.orderId}</p>
                </div>
                <div className="transaction-overview">
                    <p>支付编号:</p>
                    <p className="data">{transaction.transactionId}</p>
                </div>
                <div className="transaction-overview">
                    <p>订单创建日期:</p>
                    <p className="data">{transaction.createdAt}</p>
                </div>
                <br/>
                <p className="transaction-titles">支付信息</p>
                <hr className="div-line"/>
                <div className="transaction-status">
                    <p>支付状态:</p>
                    <p className="data">{transaction.status}</p>
                </div>
                <div className="transaction-status">
                    <p>状态更新时间:</p>
                    <p className="data">{transaction.updatedAt}</p>
                </div>
            </div>
        </div>
    );
}

export default TransactionDetails;
