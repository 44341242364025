import React, {useEffect, useState} from 'react';
import {Button, Textarea} from "@nextui-org/react";
import './dialog.css';
import {SubmitIcon} from "./icon/submitIcon";
import MenuButton from "./menu/menuButton";
import {XIcon} from "./icon/XIcon";
import {BlobServiceClient} from "@azure/storage-blob";

const {Buffer} = require('buffer');


// 将 Buffer 设置为全局对象
window.Buffer = Buffer;

function Dialog(props) {
    const [value, setValue] = React.useState("");
    const [isComposing, setIsComposing] = useState(false);
    const [fileComplete, setFileComplete] = useState(true);
    const [imageComplete, setImageComplete] = useState(true);
    useEffect(() => {
        console.log('enableFile in Dialog updated:', props.enableFile);
    }, [props.enableFile]); // This will run when enableFile changes

    useEffect(() => {
        const handlePaste = async (event) => {
            const items = event.clipboardData.items;

            // Array to hold images and files separately
            const imageFiles = [];
            const otherFiles = [];

            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.kind === 'file') {
                    event.preventDefault();
                    const file = item.getAsFile();
                    if (file.type.startsWith('image/')) {
                        imageFiles.push(file);
                    } else {
                        otherFiles.push(file);
                    }
                }
            }
            console.log('enablefile',props.enableFile)
            if ((imageFiles.length > 0 || otherFiles.length > 0)&& props.enableFile ===true) {
                props.setImages((prevImages) => [...prevImages, ...imageFiles]);
                props.setFiles((prevFiles) => [...prevFiles, ...otherFiles]);
                if (imageFiles.length > 0) {
                    // eslint-disable-next-line
                    const urls = await uploadFilesToAzure(imageFiles);
                }
                if (otherFiles.length > 0) {
                    // eslint-disable-next-line
                    const urls = await uploadFilesToAzure(otherFiles);
                }

            }

        };

        // Attach the paste event listener to the document
        document.addEventListener('paste', handlePaste);

        // Cleanup listener on component unmount
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, props.enableFile]);
    const uploadFilesToAzure = async (files) => {
        // 使用 SAS Token 而不是连接字符串
        const sasToken = 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-09-30T12:51:02Z&st=2024-09-20T04:51:02Z&spr=https,http&sig=GqA0twsTzwRTwXw8cAd6CZwUBv3wk4fNqMrf4Qi%2Bf8s%3D';
        const accountName = 'gptserverstorage';
        const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net?${sasToken}`
        );
        const containerClient = blobServiceClient.getContainerClient('file');

        const urls = [];
        for (const file of files) {
            try {
                const blobClient = containerClient.getBlockBlobClient(file.name);
                if (file.type.startsWith('image/')) {
                    setImageComplete(false)
                } else {
                    setFileComplete(false)
                }
                await blobClient.uploadData(file); // 上传文件
                const url = blobClient.url;

                // 判断文件类型是否为图片
                if (file.type.startsWith('image/')) {
                    props.setImageUrl((prevUrls) => [...prevUrls, url]); // 如果是图片，将 URL 添加到图片 state
                    setImageComplete(true)
                    console.log(`图片上传成功: ${url}`);
                } else {
                    props.setFileUrl((prevUrls) => [...prevUrls, url]); // 否则，添加到文件 state
                    setFileComplete(true)
                    console.log(`文件上传成功: ${url}`);
                }
            } catch (error) {
                setImageComplete(true)
                setFileComplete(true)
                console.error(`上传失败: ${file.name}`, error); // 错误处理
            }
        }

        return urls;
    };
    useEffect(() => {
        console.log(`file===: ${props.fileUrl.join(', ')}, image===: ${props.imageUrl.join(', ')}`);
    }, [props.fileUrl, props.imageUrl]); // 当 fileUrls 或 imageUrls 变化时打印

    const removeImage = (index) => {
        props.setImages(props.images.filter((_, i) => i !== index));
        props.setImageUrl((prevUrls) => prevUrls.filter((_, i) => i !== index));
    };

    const removeFile = (index) => {
        props.setFiles(props.files.filter((_, i) => i !== index));
        props.setFileUrl((prevUrls) => prevUrls.filter((_, i) => i !== index));
    };
    const [imageUrls, setImageUrls] = useState([]);
    useEffect(() => {
        // 创建对象 URL
        const urls = props.images.map(file => URL.createObjectURL(file));
        setImageUrls(urls);
        console.log(urls);

        // 清理对象 URL
        return () => {
            urls.forEach(url => URL.revokeObjectURL(url));
        };
    }, [props.images]);

    const truncateFileName = (name) => {
        if (name.length > 20) {
            return `${name.slice(0, 20)}...`;
        }
        return name;
    };
    const handleSubmit = () => {
        if (value.trim()) {
            props.updateSubmitValues(value);
            setValue(""); // Clear the textarea after submission
            setImageUrls([]);
            props.setFiles([]);
            props.setImages([]);
            props.setImageUrl([]);
            props.setFileUrl([]);

        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey && !isComposing) {
            event.preventDefault();
            if (props.complete === true && imageComplete ===true && fileComplete ===true) {
                handleSubmit();
            }
        }
    };
    const handleCompositionStart = () => {
        setIsComposing(true);
    };

    const handleCompositionEnd = () => {
        setIsComposing(false);
    };
    return (
        <div className={`dialog-background ${props.isOpen ? 'open' : 'closed'}`}>
            <div className="dialog-card">
                <div className="dialog-file">
                    {imageUrls.length > 0 && (
                        <div className="file-card">
                            {imageUrls.map((url, index) => {
                                    return (
                                        <div
                                            key={url}
                                            className="attach-images"
                                            style={{backgroundImage: `url(${url})`}}
                                        >
                                            <div className="attach-image-mask">
                                                <Button className="X-button  min-w-4 w-4 h-4"
                                                        size="sm"
                                                        isIconOnly
                                                        color="default"
                                                        variant="flat"
                                                        radius={'full'}
                                                        onClick={() => removeImage(index)}
                                                        aria-label="Take a photo">
                                                    <XIcon size={15}/>
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>


                    )}
                    {props.files.length > 0 && (
                        <div className="file-card">
                            {props.files.map((file, index) => {
                                    return (

                                        <div className="file-content" key={`${file.name}-${Date.now()}-${Math.random()}`}>
                                            <div
                                                className="attach-files"
                                                style={{backgroundImage: `url("/file.png")`}}>
                                                <div className="attach-file-mask">
                                                    <Button className="X-button-file min-w-4 w-4 h-4"
                                                            size="sm"
                                                            isIconOnly
                                                            color="default"
                                                            variant="flat"
                                                            radius={'full'}
                                                            onClick={() => removeFile(index)}
                                                    >
                                                        <XIcon size={15}/>
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="content-description">
                                                <div>
                                                    {truncateFileName(file.name)}
                                                </div>
                                                <div style={{color: '#797979'}}>
                                                    <h5>File</h5>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>


                    )}
                </div>
                <div className="dialog-conversation">
                    <input type="file"
                           multiple={true}
                           accept="image/*"
                           onChange={(event) => {
                               const selectedFiles = Array.from(event.target.files);
                               props.setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
                           }}
                           className="dialog-input"/>


                    <MenuButton enableFile={props.enableFile}></MenuButton>
                    <Textarea
                        placeholder="Text here"
                        classNames={{
                            inputWrapper: [
                                "textarea"
                            ],
                            input: [
                                "textarea-input"
                            ]
                        }}
                        minRows={1}
                        value={value}
                        onValueChange={setValue}
                        onKeyDown={handleKeyDown}
                        onCompositionStart={handleCompositionStart} // 监听输入法开始事件
                        onCompositionEnd={handleCompositionEnd} // 监听输入法结束事件
                    />
                    <Button
                        className={`submit-button ${value && props.complete === true && fileComplete === true && imageComplete === true ? "bg-black text-white shadow-lg" : ""}`}
                        size="sm"
                        isIconOnly
                        variant="solid"
                        aria-label="Take a photo"
                        style={{height: '24px'}}
                        onClick={handleSubmit}
                        disabled={!value || props.complete === false || fileComplete === false || imageComplete === false}
                    >
                        <SubmitIcon size={24}/>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Dialog;