import React from 'react';
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input} from "@nextui-org/react";
import {AttachmentIcon} from "../icon/attachmentIcon";
import './menuButton.css'

function MenuButton(props) {
    return (
        <div className="menu-button">
            <Input className="input" type={"file"}></Input>
            <Dropdown isDisabled={!props.enableFile}>
                <DropdownTrigger>
                    <Button isIconOnly size={"sm"} style={{height: '26px'}} color="none" variant="light">
                        <AttachmentIcon size={28}/>
                    </Button>
                </DropdownTrigger>
                <DropdownMenu aria-label="Static Actions">
                    <DropdownItem key="new">New file</DropdownItem>
                    <DropdownItem key="copy">Copy link</DropdownItem>
                    <DropdownItem key="edit">Edit file</DropdownItem>
                    <DropdownItem key="delete" className="text-danger" color="danger">
                        Delete file
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>


    );
}

export default MenuButton;