import React from "react";
export const SubmitIcon = ({
                                   fill = '#FFFFFF',
                                   filled,
                                   size,
                                   height,
                                   width,
                                   label,
                                   ...props
                               }) => {
    return (
        <svg
            width={size || width || 32}
            height={size || height || 32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z"
                fill={fill}
                stroke='none'
            />
        </svg>
    );
};
