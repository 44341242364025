import React, { useState } from "react";
import "./headPage.css";
import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger
} from "@nextui-org/react";
import { SideBar } from "./icon/sideBar";
import { Narrow } from "./icon/narrow";
import { NewChat } from "./icon/newChat";
import OrderManagement from "../../OrderManagement/OrderManagement"; // 引入 OrderManagement 组件

function HeadPage(props) {
  const [isOrderManagementOpen, setIsOrderManagementOpen] = useState(false);

  const openOrderManagement = () => {
    setIsOrderManagementOpen(true);
  };

  const closeOrderManagement = () => {
    setIsOrderManagementOpen(false);
  };

  return (
    <div className="headPage-background">
      <div className={`left-button-group ${props.isOpen ? "open" : "closed"}`}>
        <div className={`icon-button-A ${props.isOpen ? "open" : "closed"}`}>
          <Button
            className=""
            size="sm"
            isIconOnly
            color="white"
            variant="flat"
            aria-label="Take a photo"
            style={{ height: "24px" }}
            onClick={props.toggleSidebar}
          >
            <SideBar fill={"#797979"} size={24} />
          </Button>
        </div>
        <div className={`icon-button-B ${props.isOpen ? "open" : "closed"}`}>
          <Button
            className=""
            size="sm"
            isIconOnly
            color="white"
            variant="flat"
            aria-label="Take a photo"
            style={{ height: "24px" }}
          >
            <NewChat fill={"#797979"} size={24} />
          </Button>
        </div>
        <div
          className={`headPage-dropdown ${props.isOpen ? "open" : "closed"}`}
        >
          <Dropdown>
            <DropdownTrigger>
              <Button
                variant="light"
                style={{ fontSize: "1.1rem", color: "#797979" }}
              >
                Model
                <Narrow fill={"#797979"}></Narrow>
              </Button>
            </DropdownTrigger>
            <DropdownMenu
                aria-label="Single selection example"
                variant="flat"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={props.modelType}
                onSelectionChange={props.setModelType}
            >
              <DropdownItem key="gpt40" >gpt40</DropdownItem>
              <DropdownItem key="gpt40-mini" >gpt40-mini</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <div className="right-button">
        <Dropdown>
          <DropdownTrigger>
            <Button isIconOnly radius={"full"} aria-label="Like" size="sm">
              <Avatar
                className="w-8 h-8"
                color={"secondary"}
                style={{ fontSize: "0.7rem" }}
                showFallback
                name="Jane"
              />
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions">
            <DropdownItem key="new">New file</DropdownItem>
            <DropdownItem key="copy">Copy link</DropdownItem>
            <DropdownItem key="order_management" onClick={openOrderManagement}>
              订单管理
            </DropdownItem>
            <DropdownItem key="delete" className="text-danger" color="danger">
              Delete file
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      {/* 渲染 OrderManagement 组件 */}
      {isOrderManagementOpen && (
        <OrderManagement onClose={closeOrderManagement} />
      )}
    </div>
  );
}

export default HeadPage;
