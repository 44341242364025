import React from 'react';
import ConversationInput from "../conversationInput/conversationInput";
import ConversationOutput from "../conversationOutput/conversationOutput";
import './conversationCard.css'

function ConversationCard(props) {
    return (
        <div className="conversationContent-card">
            <div className="input-row">
                <ConversationInput data={props.submitValue} ></ConversationInput>
            </div>
            <div className="output-row">
                <ConversationOutput data={props.returnValue} sseState={props.sseState} uniqueKey={props.uniqueKey} setIsComplete={props.setIsComplete}></ConversationOutput>
            </div>
        </div>
    );
}

export default ConversationCard;