import React, { useState } from 'react';
import { HiOutlineX } from "react-icons/hi";
import { BsCalendar2MonthFill, BsCalendar2CheckFill, BsCalendar2HeartFill } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import { RiCustomerService2Fill } from "react-icons/ri";
import Transaction from '../Transaction/Transaction';
import './Plan.css';

function Plan({ onClose }) {
    const [showTransaction, setShowTransaction] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const handleUpgradeClick = (plan) => {
        setSelectedPlan(plan);
        setShowTransaction(true);
    };

    const handleCloseTransaction = () => {
        setShowTransaction(false);
    };

    return (
        <div className="plan-background">
            <div className="plan-header">
                <button onClick={onClose} className="close-button">
                    <HiOutlineX className="icon close-icon" />
                </button>
            </div>
            <h1 className="plan-title">订阅我们的产品</h1>
            <div className={`plan-wrapper ${showTransaction ? 'hide' : ''}`}>
                <div className="plan-container">
                    <div className="plan weekly-plan">
                        <h2 className="plan-title-with-icon">
                            <span className="icon-text">
                                <BsCalendar2MonthFill className="icon week-icon" /> <span className="highlight">月</span>订阅
                            </span>
                        </h2>
                        <p className="price">CNY ¥**/RMB</p>
                        <button className="upgrade-button" onClick={() => handleUpgradeClick('Monthly')}>
                            示例文本
                        </button>
                        <div className="feature-list">
                            <span className="feature-item"><FaCheck className="feature-icon" /> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon" /> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon" /> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon" /> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon" /> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon" /> 示例文本</span>
                        </div>
                        <div className="extra-content">
                            <h4 className="small-text">示例文本</h4>
                        </div>
                    </div>
                    <div className="vertical-divider"></div>
                    <div className="plan quarterly-plan">
                        <h2 className="plan-title-with-icon">
                            <span className="icon-text">
                                <BsCalendar2CheckFill className="icon quarter-icon"/> <span className="highlight">季度</span>订阅
                            </span>
                        </h2>
                        <p className="price">CNY ¥**/RMB</p>
                        <button className="upgrade-button" onClick={() => handleUpgradeClick('Quarterly')}>
                            示例文本
                        </button>
                        <div className="feature-list">
                            <span className="feature-item"><FaCheck className="feature-icon"/> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon"/> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon"/> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon"/> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon"/> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon"/> 示例文本</span>
                        </div>
                        <div className="extra-content">
                            <h4 className="small-text">示例文本</h4>
                        </div>
                    </div>
                    <div className="vertical-divider"></div>
                    <div className="plan annual-plan">
                        <h2 className="plan-title-with-icon">
                            <span className="icon-text">
                                <BsCalendar2HeartFill className="icon year-icon"/> <span className="highlight">年</span>订阅
                            </span>
                        </h2>
                        <p className="price">CNY ¥**/RMB</p>
                        <button className="upgrade-button" onClick={() => handleUpgradeClick('Annual')}>
                            示例文本
                        </button>
                        <div className="feature-list">
                            <span className="feature-item"><FaCheck className="feature-icon"/> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon"/> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon"/> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon"/> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon"/> 示例文本</span>
                            <span className="feature-item"><FaCheck className="feature-icon"/> 示例文本</span>
                        </div>
                        <div className="extra-content">
                            <h4 className="small-text">示例文本</h4>
                        </div>
                    </div>
                </div>
            </div>
            {showTransaction && (
                <Transaction plan={selectedPlan} onClose={handleCloseTransaction}/>
            )}
            <div className="footer">
                <RiCustomerService2Fill className="customer-service-icon"/>
                <p><button className="customer-service-link">更多需求联系我们的客服</button></p>
            </div>
        </div>
    );
}

export default Plan;
