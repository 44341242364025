import React, {useEffect, useRef, useState} from 'react';
import './conversationContent.css'
import ConversationCard from "./conversationCard/conversationCard";
import {Button} from "@nextui-org/react";
import {ScrollDown} from "./icon/scrollDown";

function ConversationContent(props) {
    const conversationRef = useRef(null);
    const buttonRef = useRef(null);
    const prevIsOpenRef = useRef(props.isOpen);
    const [showButton, setShowButton] = useState(true);
    useEffect(() => {
        const conversationContent = conversationRef.current;
        const scrollButton = buttonRef.current;
        const conversationAnimation = conversationContent.animate([
            {transform: 'translateX(0)'},
            {transform: 'translateX(100px)'}
        ], {
            duration: 300,
            easing: 'ease-in-out',
            fill: 'forwards'
        });

        const buttonAnimation = scrollButton.animate([
            {transform: 'translateX(-50%)'},
            {transform: 'translateX(84px)'}

        ], {
            duration: 300,
            easing: 'ease-in-out',
            fill: 'forwards'
        });

        conversationAnimation.pause();
        buttonAnimation.pause();


        console.log(props.isOpen)

        const prevIsOpen = prevIsOpenRef.current;

        // 只有当 `props.isOpen` 从 `true` 变为 `false` 时才执行反向动画
        if (props.isOpen && !prevIsOpen) {
            conversationAnimation?.play();
            buttonAnimation?.play();
        } else if (!props.isOpen && prevIsOpen) {
            conversationAnimation?.reverse();
            buttonAnimation?.reverse();
        }

        // 更新 `prevIsOpenRef`
        prevIsOpenRef.current = props.isOpen;


        return () => {
            conversationAnimation.cancel();
            buttonAnimation.cancel();
        };

    }, [props.isOpen]);

    const scrollToBottom = () => {
        const parentElement = conversationRef.current?.parentNode;
        if (parentElement) {
            parentElement.scroll({
                top: parentElement.scrollHeight,
                behavior: 'smooth' // 添加平滑滚动效果
            });
        } else {
            console.error("conversationContent-parent ref is not set");
        }
    };
    useEffect(() => {
        scrollToBottom();
// eslint-disable-next-line
    }, []);

    useEffect(() => {
        const parentElement = conversationRef.current?.parentNode;
        const handleScroll = () => {

            if (parentElement) {
                const {scrollTop, clientHeight, scrollHeight} = parentElement;
                if (scrollHeight <= clientHeight ||Math.abs(scrollHeight - scrollTop - clientHeight) < 1) {
                    setShowButton(false);
                } else {
                    setShowButton(true);
                }
            }
        };
        handleScroll();
        parentElement?.addEventListener('scroll', handleScroll);
        return () => {
            parentElement?.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [props.submitValue, props.returnValue]);  // 监控数据变化
    return (

        <div className="conversationContent-parent">
            <div className={`conversationContent`} ref={conversationRef}>
                {/*{props.submitValue.map((value, index) => (*/}
                {/*    <ConversationCard key={index} data={value} returnValue={props.returnValue[index]} />*/}
                {/*))}*/}
                {props.submitValue.map((submitItem, index) => (
                    <ConversationCard
                        key={submitItem.id}
                        submitValue={submitItem.value}
                        returnValue={props.returnValue.find(rv => rv.id === submitItem.id)?.value || '待更新'}
                        sseState={props.sseState}
                        uniqueKey={submitItem.id}
                        setIsComplete={props.setIsComplete}
                    />
                ))}
            </div>
            <div className="scroll-button" style={{display: showButton ? 'block' : 'none'}} ref={buttonRef}>
                <Button
                    className="bg-white border"
                    size="sm"
                    isIconOnly
                    color="default"
                    variant="flat"
                    radius="full"
                    onClick={scrollToBottom}
                >
                    <ScrollDown/>
                </Button>
            </div>

        </div>


    );
}

export default ConversationContent;