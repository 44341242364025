import React, {useState} from 'react';
import Dialog from "./dialog/dialog";
import './conversation.css'
import HeadPage from "./headPage/headPage";
import ConversationContent from "./conversationContent/conversationContent";
import Siderbar from "./Sidebar/Sidebar";
import {EventStreamContentType, fetchEventSource} from '@microsoft/fetch-event-source';


function Conversation(props) {
    const [complete,setIsComplete] = useState(true);
    const [sseStates, setSseStates] = useState({})
    const [enableFile,setEnableFile] = useState(false);
    const updateSseState = (uuid, state) => {
        setSseStates(prevStates => ({
            ...prevStates,
            [uuid]: state  // 动态更新 uuid 对应的 sseState
        }));
    };
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [fileUrl, setFileUrl] = useState([]); // 存储文件的 URLs
    const [imageUrl, setImageUrl] = useState([]); // 存储图片的 URLs
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [submitValues, setSubmitValues] = useState([]);
    const [returnValues, setReturnValues] = useState([]);
    const [modelType, setModelType] = useState(new Set(["gpt40-mini"]));
    const selectedValue = React.useMemo(
        () => Array.from(modelType).join(", ").replaceAll("_", " "),
        [modelType]
    );
    const updateSubmitValues = (newValue) => {
        const uniqueId = Date.now(); // 使用当前时间戳作为唯一标识符
        setSubmitValues(prevValues => {
            const updatedValues = [...prevValues, { id: uniqueId, value: newValue, fileUrl:fileUrl,imageUrl:imageUrl }];
            setReturnValues(prevValues => {
                const updatedReturnValues = [...prevValues, { id: uniqueId, value: '' }];
                // 通过 SSE 请求更新 returnValue
                // setupSSE(uniqueId);
                setupSSERequest(uniqueId, updatedValues, updatedReturnValues);
                return updatedReturnValues;
            });
            return updatedValues;
        });
    };

    const setupSSERequest = (uniqueId, updatedSubmitValues, updatedReturnValues) => {
        let data = '';
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const sseEndpoint = process.env.REACT_APP_SSE_ENDPOINT;
        const completeUrl = `${baseUrl}${sseEndpoint}?unique=${uniqueId}`;
        const cleanedReturnValues = updatedReturnValues.map(item => ({
            ...item,
            value: item.value.replace(/\\\\/g, '\\') // 将两个反斜杠替换为一个
        }));

        fetchEventSource(completeUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                chatList: updatedSubmitValues.map((submitItem, index) => ({
                    UserInputMessage: submitItem.value,
                    // SystemOutputMessage: updatedReturnValues[index].value
                    SystemOutputMessage: cleanedReturnValues[index].value,
                    InputFiles:{
                        FileUrl: submitItem.fileUrl || [],
                        ImageUrl: submitItem.imageUrl || []
                    },
                })),
                model: selectedValue,
                messageType:'text',
                timestamp: uniqueId,
            }),
            async onopen(response) {
                if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
                    return; // everything's good
                } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                    // client-side errors are usually non-retriable:
                    console.error('Client-side error:', response.statusText);
                } else {
                    console.error('Server-side error or retryable error:', response.statusText);
                }
            },

            onmessage(msg) {
                if (msg.event === 'FatalError') {
                    console.error('Fatal error from server:', msg.data);
                }
                console.log(msg.data);
                if (msg.event === `uuid:${uniqueId}`) {


                // 根据返回消息更新 returnValues 中的相应条目
                let newReturnValue = msg.data;
                if (newReturnValue.startsWith('"') && newReturnValue.endsWith('"')) {
                    newReturnValue = newReturnValue.substring(1, newReturnValue.length - 1);
                }
                if (containsUnicodeEscape(newReturnValue)) {
                    newReturnValue = decodeUnicode(newReturnValue);
                }
                data += newReturnValue;
                setReturnValues(prevValues => {
                    return prevValues.map(item =>
                        item.id === uniqueId ? { ...item, value: data } : item
                    );
                });
                }
            },
            onclose() {
                // if the server closes the connection unexpectedly, retry:
                console.warn('Server closed the connection, retrying...');
                updateSseState(uniqueId, true);
            },
            onerror(err) {
                console.error('SSE Error:', err);
                return undefined;
            }
        });
    };


    // eslint-disable-next-line
    function containsUnicodeEscape(str) {
        // 检查是否存在形如 \uXXXX 的模式
        return /\\u[\dA-F]{4}/i.test(str);
    }

    function decodeUnicode(str) {
        // 将 Unicode 转义序列替换为对应的字符
        return str.replace(/\\u[\dA-F]{4}/gi, function (match) {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
    }

    React.useEffect(() => {
        console.log(JSON.stringify(submitValues));
    }, [submitValues]);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    React.useEffect(() => {
        if (selectedValue==='gpt40'){
            setEnableFile(true)
            console.log('set enable file true')
        }else {
            setEnableFile(false)
            console.log('set enable file false')
        }
        console.log("Selected Value updated: ", selectedValue);
    }, [selectedValue]);
    // const fileInputRef = useRef(null);
    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);

        const imageFiles = droppedFiles.filter(file =>
            file.type.startsWith('image/')
        );
        const otherFiles = droppedFiles.filter(file =>
            !file.type.startsWith('image/')
        );


        setImages((prevImages) => [...prevImages, ...imageFiles]);
        setFiles((prevFiles) => [...prevFiles, ...otherFiles]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };
    return (
        <div className="home" onDrop={handleDrop} onDragOver={handleDragOver}>
            <div className={`conversation-container ${isSidebarOpen ? 'open' : 'closed'}`}>
            <HeadPage toggleSidebar={toggleSidebar} isOpen={isSidebarOpen}  modelType={modelType} setModelType={setModelType} selectedValue={selectedValue}></HeadPage>
            <ConversationContent submitValue={submitValues} returnValue={returnValues} className="conversationContent-A" isOpen={isSidebarOpen} sseState={sseStates} setIsComplete={setIsComplete}></ConversationContent>
            <Dialog submitValue={submitValues} setSubmitValue={setSubmitValues} updateSubmitValues={updateSubmitValues} files={files} setFiles={setFiles} images={images} isOpen={isSidebarOpen} setImages={setImages} complete={complete} fileUrl={fileUrl} imageUrl={imageUrl} setFileUrl={setFileUrl} setImageUrl={setImageUrl} enableFile={enableFile}></Dialog>
            </div>
            <Siderbar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}></Siderbar>
        </div>

    );
}

export default Conversation;