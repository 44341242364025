import React, { useEffect } from 'react';
import './OrderDetails.css';
import { HiOutlineX } from "react-icons/hi";

const allOrderDetails = [
    {
        orderId: 1,
        items: [
            { id: 1, name: 'Product 1', price: 10.00, quantity: 1 },
            { id: 2, name: 'Product 2', price: 12.00, quantity: 2 }
        ]
    },
    {
        orderId: 2,
        items: [
            { id: 3, name: 'Product 3', price: 15.00, quantity: 1 },
            { id: 4, name: 'Product 4', price: 20.00, quantity: 1 }
        ]
    },
    {
        orderId: 3,
        items: [
            { id: 5, name: 'Product 5', price: 25.00, quantity: 3 },
            { id: 6, name: 'Product 6', price: 30.00, quantity: 1 }
        ]
    }
];

const OrderDetails = ({ orderId, onClose }) => {
    const orderDetails = allOrderDetails.find(order => order.orderId === orderId);

    useEffect(() => {
        setTimeout(() => {
            document.getElementById("orderDetailsSidebar").classList.add('open');
        }, 0);
    }, []);

    const handleClose = () => {
        document.getElementById("orderDetailsSidebar").classList.remove('open');
        setTimeout(onClose, 500);
    };

    return (
        <div id="orderDetailsSidebar" className="order-details">
            <div className="order-details-header">
                <button className="close-button" onClick={handleClose}>
                    <HiOutlineX className="icon close-icon" />
                </button>
            </div>
            <div className="order-details-content">
                <h2>订单详情</h2>
                <p className="order-titles">物品详情</p>
                <hr className="div-line"/>
                {orderDetails.items.map(item => (
                    <div key={item.id} className="order-item">
                        <div className="item-info">
                            <p>物品ID:</p>
                            <p className="data">{item.id}</p>
                        </div>
                        <div className="item-info">
                            <p>物品名称:</p>
                            <p className="data">{item.name}</p>
                        </div>
                        <div className="item-info">
                            <p>价格:</p>
                            <p className="data">RMB￥{item.price.toFixed(2)}</p>
                        </div>
                        <div className="item-info">
                            <p>数量:</p>
                            <p className="data">{item.quantity}</p>
                        </div>
                        <hr className="item-divider"/>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OrderDetails;
