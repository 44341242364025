import Conversation from "./components/conversation/conversation";

function App() {
  return (
    <div className="App">
      <Conversation></Conversation>
    </div>
  );
}

export default App;
