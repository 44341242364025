import React, {useEffect, useState} from 'react';
import './conversationOutput.css'
import {Avatar, Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow} from "@nextui-org/react";
import {CodeBlock, androidstudio} from 'react-code-blocks';
import {CopyIcon} from "../icon/CopyIcon";
import {useCopyToClipboard} from 'react-use';
import {BottomCopyIcon} from "../icon/BottomCopyIcon";
import Latex from "react-latex-next";


function ConversationOutput(props) {
    // eslint-disable-next-line
    const [state, copyToClipboard] = useCopyToClipboard();
    const [clipboardState, setClipboardState] = useState({});
    // eslint-disable-next-line
    const [contentState, copyToContent] = useCopyToClipboard();

    const copyCode = (copyValue) => {
        copyToClipboard(copyValue.content.toString());
        setClipboardState(prevState => ({
            ...prevState,
            [copyValue.key]: true, // 设置该 key 对应的状态为已复制
        }));
        setTimeout(() => {
            setClipboardState(prevState => ({
                ...prevState,
                [copyValue.key]: false, // 重置为 false
            }));
        }, 3000);

    };
    const copyContent = () => {
        let newText = '';
        const lines = props.data.split('\\n');
        lines.forEach((line, i) => {
            newText += line + '\n';
        })

        copyToContent(newText);
    };
    function isLetter(char) {
        return (char >= 'a' && char <= 'z') || (char >= 'A' && char <= 'Z');
    }
    function replaceBacktickWrappedSections(data, placeholderMap, count = { value: 0 }) {
        let result = '';
        let i = 0;

        while (i < data.length) {
            // 检查是否遇到三重反引号块 ```
            if (data.startsWith('```', i)) {
                let startBlock = i + 3;
                let endBlock = data.indexOf('```', startBlock);
                if (endBlock === -1) {
                    endBlock = data.length;
                }

                // 提取代码块内部内容
                let codeContent = data.slice(startBlock, endBlock);

                // 处理代码块内容
                let processedContent = replaceBacktickWrappedSections(codeContent, placeholderMap, count);

                // 重建代码块并添加到结果
                result += '```' + processedContent + '```';

                i = endBlock + 3; // 跳过整个代码块
                continue;
            }

            // 检查是否遇到单引号、双引号或反引号
            if (data[i] === '"' || data[i] === '\'' || data[i] === '`') {
                let quoteType = data[i];
                let startQuote = i;

                // 对于单引号进行额外检查，不用正则，直接检查字符
                if (quoteType === '\'' && i > 0 && i + 1 < data.length && isLetter(data[i - 1]) && isLetter(data[i + 1])) {
                    // 这是一个名词所有格，如 Tom's，不进行特殊处理
                    result += data[i];
                    i++;
                    continue;
                }

                i++;
                while (i < data.length) {
                    if (data[i] === '\\' && data[i + 1] === quoteType) {
                        i += 2; // 跳过被转义的引号字符
                    } else if (data[i] === '\\' && data[i + 1] === '\\') {
                        i += 2; // 跳过转义的反斜杠
                    } else if (data[i] === quoteType) {
                        break;
                    } else {
                        i++;
                    }
                }
                if (i >= data.length || data[i] !== quoteType) {
                    // 如果未找到结束引号，添加剩余部分并退出
                    result += data.slice(startQuote);
                    break;
                }

                // 提取引号包裹的字符串
                let content = data.slice(startQuote, i + 1);

                // 检查内容是否包含反斜杠
                if (content.includes('\\')) {
                    let placeholder = `__PLACEHOLDER_${count.value++}__`;
                    placeholderMap[placeholder] = content;
                    result += placeholder;
                } else {
                    result += content;
                }

                i++; // 跳过结束引号
                continue;
            }
            // 添加当前字符
            result += data[i];
            i++;
        }

        return result;
    }
// eslint-disable-next-line
    function processContent(content, placeholderMap, count) {
        let result = '';
        let i = 0;

        while (i < content.length) {
            // 检查是否遇到单引号、双引号或反引号
            if (content[i] === '"' || content[i] === '\'' || content[i] === '`') {
                let quoteType = content[i];
                let startQuote = i;
                i++;
                while (i < content.length) {
                    if (content[i] === '\\') {
                        i += 2; // 跳过转义字符
                    } else if (content[i] === quoteType) {
                        break;
                    } else {
                        i++;
                    }
                }
                if (i >= content.length || content[i] !== quoteType) {
                    // 如果未找到结束引号，添加剩余部分并退出
                    result += content.slice(startQuote);
                    break;
                }

                // 提取引号包裹的字符串
                let quotedContent = content.slice(startQuote, i + 1);

                // 检查内容是否包含反斜杠
                if (quotedContent.includes('\\')) {
                    let placeholder = `__PLACEHOLDER_${count++}__`;
                    placeholderMap[placeholder] = quotedContent;
                    result += placeholder;
                } else {
                    result += quotedContent;
                }

                i++; // 跳过结束引号
                continue;
            }


            // 添加当前字符
            result += content[i];
            i++;
        }

        placeholderMap.__count = count; // 更新计数
        return result;
    }

    function restoreQuotedSections(data, placeholderMap) {
        // 恢复占位符为原始内容
        Object.keys(placeholderMap).forEach(placeholder => {
            data = data.replace(new RegExp(placeholder, 'g'), placeholderMap[placeholder]);
        });
        return data;
    }



    const formatData = (data) => {
        let placeholderMap = {};

        let tempData = data.replace(/\\neq/g, '__NEQ__');
        tempData = replaceBacktickWrappedSections(tempData,placeholderMap);
// 然后以 `\\n` 分割行
        const tmplines = tempData.split('\\n');

// 遍历每一行，并将占位符替换回 `\\neq`
//         let lines = tmplines.map(line => line.replace(/__NEQ__/g, '\\neq').replace(/__nnn__/g, `\\n`).replace(/__rrrnnn__/g, `\\r\\n`));
        let lines = tmplines.map(line => {
            // 先执行replace操作
            line = line.replace(/__NEQ__/g, '\\neq');
            // 然后执行restoreQuotedSections操作
            return restoreQuotedSections(line, placeholderMap);
            // return line;
        });
        let result = [];
        let insideCodeBlock = false;
        // let insideHttp = false;
        let currentText = '';
        let insideBold = false;
        let codeLanguage = '';
        let insideInlineCode = false;
        let previousWasEmpty = false;
        let insideItalic = false;
        let insideTable = false; // 判断是否在表格内
        let tableData = [];
        let isTableHeader = false; // 判断是否在表格头
        let newCodeBlocksContent = [];
        let insideMathBlock = false;
        let headerColumns = 0;  // 初始化 headerColumns 为 0
        let validTable = true;
        let unprocessedTable = [];
        const replaceDoubleBackslashes = (latexString) => {
            return latexString.replace(/\\\\/g, '\\');
        };
        lines.forEach((line, lineIndex) => {

            function parseInlineFormatting(text) {
                let parsedLine = [];
                let tempText = '';
                let i = 0;
                let insideBold = false;
                let insideItalic = false;
                let insideInlineCode = false;

                while (i < text.length) {
                    if (text[i] === '*' && text[i + 1] === '*') {
                        if (insideBold) {
                            if (tempText) {
                                parsedLine.push(<strong key={`bold-${i}`}>{tempText}</strong>);
                                tempText = '';
                            }
                            insideBold = false;
                        } else {
                            if (tempText) {
                                parsedLine.push(<span key={`text-${i}`}>{tempText.replace(/\\\\/g, '\\')}</span>);
                                tempText = '';
                            }
                            insideBold = true;
                        }
                        i += 2;
                    } else if (text[i] === '`') {
                        if (insideInlineCode) {
                            if (tempText) {
                                parsedLine.push(<code key={`inline-code-${i}`}>{tempText}</code>);
                                tempText = '';
                            }
                            insideInlineCode = false;
                        } else {
                            if (tempText) {
                                parsedLine.push(<span key={`text-${i}`}>{tempText.replace(/\\\\/g, '\\')}</span>);
                                tempText = '';
                            }
                            insideInlineCode = true;
                        }
                        i += 1;
                    } else if (text[i] === '*') {
                        if (insideItalic) {
                            if (tempText) {
                                parsedLine.push(<em key={`italic-${i}`}>{tempText}</em>);
                                tempText = '';
                            }
                            insideItalic = false;
                        } else {
                            if (tempText) {
                                parsedLine.push(<span key={`text-${i}`}>{tempText.replace(/\\\\/g, '\\')}</span>);
                                tempText = '';
                            }
                            insideItalic = true;
                        }
                        i += 1;
                    } else {
                        tempText += text[i];
                        i += 1;
                    }
                }

                // 处理剩余的文本
                if (tempText) {
                    if (insideBold) {
                        parsedLine.push(<strong key={`bold-${i}`}>{tempText}</strong>);
                    } else if (insideItalic) {
                        parsedLine.push(<em key={`italic-${i}`}>{tempText}</em>);
                    } else if (insideInlineCode) {
                        parsedLine.push(<code key={`inline-code-${i}`}>{tempText}</code>);
                    } else {
                        parsedLine.push(<span key={`text-${i}`}>{tempText.replace(/\\\\/g, '\\')}</span>);
                    }
                }

                return parsedLine;
            }

            // 处理代码块开始或结束
            const trimmedLine = line.trim();
            // const isMathStart = trimmedLine.startsWith('$$') || trimmedLine.startsWith('\\\\[');
            // const isMathEnd = trimmedLine.startsWith('$$') || trimmedLine.startsWith('\\\\]');
            const isMathStart = trimmedLine.startsWith('$$') || trimmedLine.includes('\\\\[');
            const isMathEnd = trimmedLine.startsWith('$$') || trimmedLine.includes('\\\\]');

            if (insideMathBlock) {
                if (isMathEnd) {
                    if (line) {
                        line = line.replace('\\\\]', '').trim();
                        // line = line.replace('\\\\)','').trim();
                    }
                    currentText += line;
                    const processedFormula = replaceDoubleBackslashes(currentText.trim());
                    console.log("end ====block", line, processedFormula);


                    result.push(<br/>);
                    result.push(
                        <div key={`math-block-${lineIndex}`} className={'math-content'}>
                            <Latex>{`$$${processedFormula}$$`}</Latex>
                        </div>
                    );
                    result.push(<br key={`break-after-math-block-${lineIndex}`}/>);
                    currentText = '';
                    insideMathBlock = false;
                } else {
                    currentText += line + '\n';
                }
                return;
            } else if (isMathStart) {
                insideMathBlock = true;
                console.log("enter block");
                currentText = '';
                if (line) {
                    line = line.replace('\\\\[', '').trim();
                    // line = line.replace('\\\\(','').trim();
                }
                currentText += line
                return;
            }
            if (trimmedLine.startsWith('```')) {
                if (insideCodeBlock) {
                    // 结束代码块并推入结果
                    if (currentText.trim() !== '') {
                        // currentText = currentText.replace(/\\\\n/g, '\n').trim();
                        let formattedText = '';
                        let insideSingleQuote = false;
                        let insideDoubleQuote = false;

                        for (let i = 0; i < currentText.length; i++) {
                            const char = currentText[i];
                            const nextChar = currentText[i + 1];

                            // 检查是否进入或退出单引号
                            if (char === "'" && (i === 0 || currentText[i - 1] !== '\\')) {
                                insideSingleQuote = !insideSingleQuote;
                            }

                            // 检查是否进入或退出双引号
                            if (char === '"' && (i === 0 || currentText[i - 1] !== '\\')) {
                                insideDoubleQuote = !insideDoubleQuote;
                            }

                            // 如果遇到 \n 且不在引号中，替换为换行符
                            if (char === '\\' && nextChar === 'n' && !insideSingleQuote && !insideDoubleQuote) {
                                formattedText += '\n';
                                i++; // 跳过 'n'，因为已经处理了它
                            } else {
                                // 否则正常追加字符
                                formattedText += char;
                            }
                        }

                        console.log('coldeblock-=====',currentText)
                        result.push(
                            <div className={'code-style'} key={`code-block-${lineIndex}`}>
                                <CodeBlock
                                    text={formattedText.replace(/\\\\/g, '\\')}
                                    language={codeLanguage}
                                    showLineNumbers={false}
                                    wrapLines={true}
                                    theme={androidstudio}
                                    customStyle={{
                                        fontSize: '0.9rem',
                                        borderRadius: '5px',
                                    }}
                                />
                                <div className={'copy-button-mask'}>
                                    <div className={'copy-button'}>
                                        <Button
                                            isIconOnly
                                            className="min-w-6 w-14 h-6 rounded-none"
                                            style={{borderRadius: '3px'}}
                                            size="sm"
                                            onClick={() => copyCode(newCodeBlocksContent.find(block => block.key === lineIndex))}
                                        >
                                            <CopyIcon stroke={'#6B7280'} size={16}/>
                                            <div style={{color: '#6B7280'}}>
                                                {clipboardState[lineIndex] ? 'Done' : 'Copy'}
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        );
                        newCodeBlocksContent.push({
                            key: lineIndex,
                            content: currentText.trim(),
                        });
                    }
                    currentText = ''; // 清空临时文本
                    codeLanguage = ''; // 清空语言标识
                    insideCodeBlock = false; // 切换状态
                } else {
                    // 开始代码块
                    const language = trimmedLine.slice(3).trim();
                    codeLanguage = language || 'plaintext';
                    insideCodeBlock = true;
                }
                return; // 继续下一行
            }

            if (insideCodeBlock) {
                currentText += line + '\n';
                //特殊处理markdown表格
                if (line.startsWith('|')&&line.endsWith('|')){
                    const nextLine = lines[lineIndex + 1] || ''; // 获取下一行
                    const tempLine =nextLine.trim().replace(" ", "");
                    if (tempLine.startsWith('|-')||tempLine.startsWith('|:-')) {
                        //0宽度占位符
                        currentText += '\u200B';
                    }
                }
                return;
            }

            if (!insideTable) {
                if (line.trim().startsWith('|') && line.trim().endsWith('|')) {
                    const nextLine = lines[lineIndex + 1] || ''; // 获取下一行
                    const tmpLine = nextLine.trim().replace(' ','');
                    if (tmpLine.startsWith('|-')) {
                        // 下一行是表格的分隔符行，确认是表格的开始
                        insideTable = true;
                        tableData = [];
                        unprocessedTable = [];
                        isTableHeader = true;

                    }
                }
            }
            // if (insideTable) {
            //     if (line.includes('|')) {
            //         const cells = line.split('|').slice(1, -1).map(cell => cell.trim().replace(/\\\\/g, '\\'));
            //         if (isTableHeader) {
            //             if (cells.every(cell => /^-+$/.test(cell))) {
            //                 isTableHeader = false;
            //             } else {
            //                 tableData.push({type: 'header', row: cells});
            //             }
            //         } else {
            //             if (!cells.every(cell => /^-+$/.test(cell))) {
            //                 tableData.push({type: 'body', row: cells});
            //             }
            //         }
            //     } else {
            //         // 结束表格
            //         if (insideTable) {
            //             result.push(
            //                 <div className={'output-table'}>
            //                     <Table removeWrapper aria-label="Dynamic Table Example" key={`table-${lineIndex}`}
            //                            classNames={{
            //                                wrapper: "max-w-3xl",  // 控制表格最大宽度
            //                                th: "border border-gray-300",  // 表头的边框样式
            //                                td: "border border-gray-300",  // 单元格的边框样式
            //
            //                            }}>
            //                         <TableHeader>
            //                             {tableData.filter(item => item.type === 'header').map((header) => (
            //                                 header.row.map((cell, cellIndex) => (
            //                                     <TableColumn key={`header-cell-${cellIndex}`}>{cell}</TableColumn>
            //                                 ))
            //                             ))}
            //                         </TableHeader>
            //                         <TableBody>
            //                             {tableData.filter(item => item.type === 'body').map((rowData, rowIndex) => {
            //                                 const headerColumns = tableData.find(item => item.type === 'header')?.row.length || 0;
            //                                 const cells = rowData.row.length < headerColumns
            //                                     ? [...rowData.row, ...Array(headerColumns - rowData.row.length).fill('')] // 填充缺少的单元格
            //                                     : rowData.row;
            //
            //                                 return (
            //                                     <TableRow key={`row-${rowIndex}`}>
            //                                         {cells.map((cell, cellIndex) => (
            //                                             <TableCell
            //                                                 key={`cell-${cellIndex}`}>{cell || '\u00A0'}
            //                                             </TableCell>
            //                                         ))}
            //                                     </TableRow>
            //                                 );
            //                             })}
            //                         </TableBody>
            //                     </Table>
            //                 </div>
            //             );
            //             tableData = [];
            //             insideTable = false;
            //         }
            //     }
            //     return; // 继续下一行
            // }

            if (insideTable) {
                if (line.includes('|')) {
                    const cells = line.split('|').slice(1, -1).map(cell => cell.trim());
                    unprocessedTable.push(line);
                    // 判断是否是表头行
                    if (isTableHeader) {
                        if (cells.every(cell => /^-+$/.test(cell))) {
                            isTableHeader = false;
                        } else {
                            headerColumns = cells.length;  // 记录表头的列数，表头行只赋值一次
                            tableData.push({ type: 'header', row: cells });
                        }
                    } else {
                        // 检查当前行的单元格数是否匹配表头的列数
                        if (cells.length !== headerColumns) {
                            validTable = false;  // 表示表格无效
                        } else {
                            tableData.push({ type: 'body', row: cells });
                        }
                    }
                } else {
                    // 如果遇到非表格行，结束表格解析
                    if (insideTable) {
                        if (validTable) {
                            // 只有在表格有效的情况下才进行渲染
                            result.push(
                                <div className={'output-table'}>
                                    <Table removeWrapper aria-label="Dynamic Table Example" key={`table-${lineIndex}`}
                                           classNames={{
                                               wrapper: "max-w-3xl",  // 控制表格最大宽度
                                               th: "border border-gray-300",  // 表头的边框样式
                                               td: "border border-gray-300",  // 单元格的边框样式
                                           }}>
                                        <TableHeader>
                                            {tableData.filter(item => item.type === 'header').map((header) => (
                                                header.row.map((cell, cellIndex) => (
                                                    <TableColumn key={`header-cell-${cellIndex}`}>{cell}</TableColumn>
                                                ))
                                            ))}
                                        </TableHeader>
                                        <TableBody>
                                            {tableData.filter(item => item.type === 'body').map((rowData, rowIndex) => {
                                                const cells = rowData.row;
                                                return (
                                                    <TableRow key={`row-${rowIndex}`}>
                                                        {cells.map((cell, cellIndex) => (
                                                            <TableCell key={`cell-${cellIndex}`}>{cell || '\u00A0'}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                            );
                        } else {
                            // 表格无效，输出提示或跳过渲染
                            result.push(
                                <div className={'code-style'} key={`code-block-${lineIndex}`}>
                                    <CodeBlock
                                        text={unprocessedTable.join('\n').replace(/\\\\/g, '\\')}  // 将表格内容拼接为字符串
                                        language={'text'}  // 设置语言为 text
                                        showLineNumbers={false}
                                        wrapLines={true}
                                        theme={androidstudio}
                                        customStyle={{
                                            fontSize: '0.9rem',
                                            borderRadius: '5px',
                                        }}
                                    />
                                    <div className={'copy-button-mask'}>
                                        {/*<div className={'copy-button'}>*/}
                                        {/*    <Button*/}
                                        {/*        isIconOnly*/}
                                        {/*        className="min-w-6 w-14 h-6 rounded-none"*/}
                                        {/*        style={{borderRadius: '3px'}}*/}
                                        {/*        size="sm"*/}
                                        {/*        onClick={() => copyCode(unprocessedTable.join('\n'))}  // 复制整个表格内容*/}
                                        {/*    >*/}
                                        {/*        <CopyIcon stroke={'#6B7280'} size={16}/>*/}
                                        {/*        <div style={{color: '#6B7280'}}>*/}
                                        {/*            {clipboardState[lineIndex] ? 'Done' : 'Copy'}*/}
                                        {/*        </div>*/}
                                        {/*    </Button>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            );
                        }

                        tableData = [];
                        unprocessedTable = [];
                        insideTable = false;
                        validTable = true;  // 重置有效标志
                    }
                }

                return;  // 确保跳出当前行的处理，继续下一行
            }



            if (line.startsWith('#')) {
                // 计算有多少个 # 号
                const hashCount = line.match(/^#+/)[0].length;

                // 将最多支持 6 个 #，超过 6 个也按照 h6 处理
                const headerLevel = Math.min(hashCount, 6);

                // 获取标题文本
                const headerText = line.slice(hashCount).trim();
                const parsedHeaderText = parseInlineFormatting(headerText);
                // 生成对应的 h1 到 h6 标签
                result.push(
                    React.createElement(
                        `h${headerLevel}`,
                        {key: `header-${lineIndex}`, className: "font-bold text-lg my-2"},
                        parsedHeaderText
                    )
                );
                return;
            }

            // 处理加粗、内联代码及普通文本
            let parsedLine = [];
            let tempText = '';
            let i = 0;
            while (i < line.length) {
                // 处理行内数学公式


                if (line[i] === '\\' && line[i + 1] === '\\' && line[i + 2] === '(') {
                    if (tempText !== '') {
                        // 确保 tempText 有内容才推送
                        parsedLine.push(<span key={`text-${i}`}>{tempText.replace(/\\\\/g, '\\') || ''}</span>);
                        console.log(`Added text: ${tempText}`, parsedLine);
                        tempText = ''; // 清空 tempText，准备处理公式
                    }
                    // 找到数学公式结束符 `\\)`
                    let mathEnd = line.indexOf('\\\\)', i + 3); // 从公式开始后查找结束符
                    if (mathEnd !== -1) {
                        // 提取公式内容
                        let mathContent = line.slice(i + 3, mathEnd);
                        mathContent = mathContent.replace(/\\\\/g, '\\');
                        if (mathContent) {
                            parsedLine.push(
                                // <Latex key={`inline-math-${i}`}>{mathContent}</Latex>
                                <Latex>{`$${mathContent}$`}</Latex>
                                // <Latex key={`inline-math-${i}`}>{`\$begin:math:text$${mathContent}\\$end:math:text$`}</Latex>
                            );
                            console.log(`Added math: ${mathContent}`, parsedLine);
                            i = mathEnd + 3;
                            if (i > line.length) {
                                i = line.length;
                            }// 跳过公式内容并继续处理剩余文本
                        }
                        tempText = ''; // 清空 tempText
                    } else {
                        // 如果没有找到结束符，处理剩余文本
                        console.log("no tail")
                        tempText += line.slice(i);
                        break;
                    }
                }
                if (line[i] === '*' && line[i + 1] === '*') {
                    if (insideBold) {
                        if (tempText) {
                            parsedLine.push(<strong key={`bold-${i}`}>{tempText}</strong>);
                        }
                        tempText = '';
                        insideBold = false;
                    } else {
                        if (tempText) {
                            parsedLine.push(<span key={`text-${i}`}>{tempText.replace(/\\\\/g, '\\')}</span>);
                        }
                        tempText = '';
                        insideBold = true;
                    }
                    i += 2;
                } else if (line[i] === '`') {
                    if (insideInlineCode) {
                        if (tempText) {
                            parsedLine.push(<code key={`inline-code-${i}`}
                                                  className="">{tempText.replace(/\\\\/g, '\\')}</code>);
                        }
                        tempText = '';
                        insideInlineCode = false;
                    } else {
                        if (tempText) {
                            parsedLine.push(<span key={`text-${i}`}>{tempText.replace(/\\\\/g, '\\')}</span>);
                        }
                        tempText = '';
                        insideInlineCode = true;
                    }
                    i += 1;
                } else if (line[i] === '*') {
                    if (insideItalic) {
                        if (tempText) {
                            parsedLine.push(<strong key={`italic-${i}`}
                                                    style={{fontStyle: 'italic'}}>{tempText}</strong>);
                        }
                        tempText = '';
                        insideItalic = false;
                    } else {
                        if (tempText) {
                            parsedLine.push(<span key={`text-${i}`}>{tempText.replace(/\\\\/g, '\\')}</span>);
                        }
                        tempText = '';
                        insideItalic = true;
                    }
                    i += 1;
                } else {
                    tempText += line[i];
                    i += 1;
                }
            }
            console.log('tempText before push:', tempText);
            console.log(typeof tempText)
            tempText = tempText.replace("undefined", "");

            if (tempText !== 'undefined' && tempText !== '') {
                if (insideBold) {
                    parsedLine.push(<strong key={`bold-end-${i}`}>{tempText.replace(/\\\\/g, '\\')}</strong>);
                } else if (insideInlineCode) {
                    parsedLine.push(<code key={`inline-code-end-${i}`}
                                          className="bg-gray-200 p-1 rounded">{tempText.replace(/\\\\/g, '\\')}</code>);
                } else {
                    parsedLine.push(<span key={`text-end-${i}`}>{tempText.replace(/\\\\/g, '\\') || ''}</span>);
                }
                tempText = '';
            }

            insideBold = false;
            insideInlineCode = false;

            if (line.trim() === '') {
                if (!previousWasEmpty) {
                    result.push(<br key={`br-${lineIndex}`}/>);
                    previousWasEmpty = true;
                }
            } else {
                previousWasEmpty = false;
            }

            result.push(<p key={`text-${lineIndex}`}>{parsedLine}</p>);
        });

        // 如果文件末尾仍然在表格内
        if (insideTable && tableData.length > 0 ) {
            result.push(
                <div className={'output-table'}>
                    <Table removeWrapper aria-label="Dynamic Table Example" key={`table-end`}
                           classNames={{
                               wrapper: "max-w-3xl",  // 控制表格最大宽度
                               th: "border border-gray-300",  // 表头的边框样式
                               td: "border border-gray-300",  // 单元格的边框样式
                           }}>
                        >
                        <TableHeader>
                            {tableData.filter(item => item.type === 'header').map((header) => (
                                header.row.map((cell, cellIndex) => (
                                    <TableColumn key={`header-cell-end-${cellIndex}`}>{cell}</TableColumn>
                                ))
                            ))}
                        </TableHeader>
                        <TableBody>
                            {tableData.filter(item => item.type === 'body').map((rowData, rowIndex) => {
                                const headerColumns = tableData.find(item => item.type === 'header')?.row.length || 0;
                                const cells = rowData.row.length < headerColumns
                                    ? [...rowData.row, ...Array(headerColumns - rowData.row.length).fill('')] // 填充缺少的单元格
                                    : rowData.row;

                                return (
                                    <TableRow key={`row-end-${rowIndex}`}>
                                        {cells.map((cell, cellIndex) => (
                                            <TableCell key={`cell-end-${cellIndex}`}>{cell || '\u00A0'}</TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            );
        }
        if (insideCodeBlock) {
            result.push(
                <div className={'code-style'} key={`code-block-${lines.length}`}>
                    <CodeBlock
                        text={currentText.trim()}
                        language={codeLanguage}
                        showLineNumbers={false}
                        wrapLines={true}
                        theme={androidstudio}
                        customStyle={{
                            fontSize: '0.9rem',
                            borderRadius: '5px',
                        }}
                    />
                    <div className={'copy-button-mask'}>
                        <div className={'copy-button'}>
                            <Button
                                isIconOnly
                                className="min-w-6 w-14 h-6 rounded-none"
                                style={{borderRadius: '3px'}}
                                size="sm"
                                onClick={() => copyCode(newCodeBlocksContent.find(block => block.key === lines.length))}
                            >
                                <CopyIcon stroke={'#6B7280'} size={16}/>
                                <div style={{color: '#6B7280'}}>
                                    {clipboardState[lines.length] ? 'Done' : 'Copy'}
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            );
            newCodeBlocksContent.push({
                key: lines.length,
                content: currentText.trim(),
            });
        }
        return result;
    };


    useEffect(() => {
        console.log("state", props.sseState[props.uniqueKey])
        if (props.sseState[props.uniqueKey]) {
            props.setIsComplete(true);  // 当条件为 true 时，设置 isComplete 为 true
        } else {
            props.setIsComplete(false)
        }
    }, [props, props.sseState, props.uniqueKey]);

    return (
        <div className="conversationOutput-background">
            <div className="output-sidebar">
                <Avatar src="/openai.png" size={"sm"}/>
            </div>
            <div className="output-content">
                <div>
                    {formatData(props.data)}
                </div>
                {props.sseState[props.uniqueKey] && (
                    <div>
                        <Button
                            isIconOnly
                            className="min-w-6 w-6 h-6 rounded-none"
                            style={{borderRadius: '3px', backgroundColor: 'transparent'}}
                            size="sm"
                            onClick={copyContent}
                        >
                            <BottomCopyIcon stroke={'#6B7280'} size={16}/>
                        </Button>
                    </div>
                )
                }
            </div>
        </div>
    );
}

export default ConversationOutput;