import React, { useState } from "react";
import "./Sidebar.css";
import { SideBar } from "./icon/sideBar";
import { Button } from "@nextui-org/react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/dropdown";
import { NewChat } from "./icon/newChat";
import { ChatGPTLogo } from "./icon/ChatGPTLogo";
import { UpgradeLogo } from "./icon/upgradeLogo";
import { Select } from "./icon/select";
import { Rename } from "./icon/rename";
import { Delete } from "./icon/delete";
import Plan from "../../Subscription/Plan/Plan"; // Import Plan component

function Siderbar(props) {
  const initialSessions = [
    {
      groupName: "收藏",
      items: [
        { id: 1, title: "Table Built Table Built" },
        { id: 2, title: "Table Built Table Built" },
      ],
    },
    {
      groupName: "今天",
      items: [
        { id: 3, title: "React CSS Sidebar Implement" },
        { id: 4, title: "React CSS Sidebar Implement" },
      ],
    },
    {
      groupName: "昨天",
      items: [
        { id: 5, title: "Identify Columns With Nulls" },
        { id: 6, title: "Identify Columns With Nulls" },
      ],
    },
    {
      groupName: "前7天",
      items: [
        { id: 7, title: "Centered Button with Effect" },
        { id: 8, title: "React Logo Border Style" },
      ],
    },
    {
      groupName: "前30天",
      items: [
        { id: 9, title: "React JS and HTML Integration Setup" },
        { id: 10, title: "Rider .NET Core Web API" },
        { id: 11, title: "React JS and HTML Integration Setup" },
        { id: 12, title: "Rider .NET Core Web API" },
        { id: 13, title: "CRUD Operations in .NET" },
        { id: 14, title: "WebStorm React Setup" },
      ],
    },
  ];
  const [groupedSessions, setGroupedSessions] = useState(initialSessions);
  const [isEditable, setIsEditable] = useState(false);
  const [isPlanOpen, setIsPlanOpen] = useState(false); // State to control Plan component visibility

  // Example function to update groupedSessions
  const updateGroupedSessions = () => {
    // This is a simple example to show how you might update the state
    const newSession = {
      groupName: "新组",
      items: [{ id: 15, title: "New Session Item" }],
    };
    setGroupedSessions([...groupedSessions, newSession]);
  };

  // Example function to enable editing
  const enableEditing = () => {
    setIsEditable(true);
  };

  const handleUpgradeClick = () => {
    setIsPlanOpen(true);
  };

  const handleClosePlan = () => {
    setIsPlanOpen(false);
  };

  return (
      <>
        <div className={`sidebar ${props.isOpen ? "open" : "closed"}`}>
          {props.isOpen && (
              <div className="siderbar-content">
                <div className="siderbar-nav">
                  <div className="siderbar-top">
                <span className="icon-buttons">
                  <Button
                      className="button-group"
                      isIconOnly
                      color="white"
                      variant="flat"
                      aria-label="Toggle Sidebar"
                      onClick={props.toggleSidebar}
                  >
                    <SideBar className="svg-icon" fill={"#0d0d0d"} size={24} />
                  </Button>
                </span>
                    <span className="icon-buttons">
                  <Button
                      className="button-group"
                      isIconOnly
                      color="white"
                      variant="flat"
                      aria-label="New Chat"
                      onClick={updateGroupedSessions} // Example usage
                  >
                    <NewChat fill={"#0d0d0d"} size={24} />
                  </Button>
                </span>
                  </div>
                  <div className="siderbar-middle">
                    <div className="siderbar-middle-top">
                      <div
                          className="newchat-group group/item active:scale-95"
                          tabIndex="0"
                      >
                        <a className="newchat-content" href="/">
                          <div className="circlestyle">
                            <Button
                                className="newchat-icon svg"
                                size="sm"
                                isIconOnly
                                color="white"
                                variant="flat"
                                aria-label="ChatGPT logo"
                                onClick={enableEditing} // Example usage
                            >
                              <ChatGPTLogo fill={"#0d0d0d"} size={16} />
                            </Button>
                          </div>
                          <div className="newchat-word">ChatGPT</div>
                          <div className="newchat-icon invisible group-hover/item:visible">
                            <Button
                                className="button-icon svg"
                                size="sm"
                                isIconOnly
                                color="white"
                                variant="flat"
                                aria-label="New Chat"
                            >
                              <NewChat fill={"#0d0d0d"} size={18} />
                            </Button>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="siderbar-middle-session">
                      {groupedSessions.map(
                          (group) =>
                              group.items.length > 0 && (
                                  <div
                                      className="session-item"
                                      tabIndex="0"
                                      key={group.groupName}
                                  >
                                    <div className="date"> {group.groupName}</div>
                                    <ol>
                                      {group.items.map((item) => (
                                          <li
                                              className="session-titles group/item"
                                              key={item.id}
                                          >
                                            <a
                                                className="title"
                                                href="/"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                              <div className="title-css">
                                                <input
                                                    value={item.title}
                                                    type="text"
                                                    readOnly={!isEditable} // Example usage
                                                    // onDoubleClick={()=>handleDoubleClick(item)}
                                                    style={{
                                                      backgroundColor: "transparent",
                                                      border: "0",
                                                      width: "200px",
                                                      outline: isEditable
                                                          ? undefined
                                                          : "none",
                                                    }}
                                                />
                                                <div className="title-css2 shadow-lg"></div>
                                              </div>
                                            </a>
                                            <div className="selects invisible group-hover/item:visible">
                                              <Dropdown placement="bottom-start">
                                                <DropdownTrigger>
                                                  <Button
                                                      className="button-icon svg"
                                                      size="sm"
                                                      isIconOnly
                                                      color="foreground"
                                                      variant="solid"
                                                      aria-label="More options"
                                                  >
                                                    <Select fill={"#0d0d0d"} size={18} />
                                                  </Button>
                                                </DropdownTrigger>
                                                <DropdownMenu
                                                    variant="flat"
                                                    aria-label="Dropdown menu with icons"
                                                >
                                                  <DropdownItem
                                                      key={item.id}
                                                      // onClick={()=>handleOnRename(item)}
                                                      startContent={
                                                        <Rename fill={"#0d0d0d"} size={18} />
                                                      }
                                                  >
                                                    重命名
                                                  </DropdownItem>
                                                  <DropdownItem
                                                      key="delete"
                                                      className="text-danger"
                                                      color="danger"
                                                      startContent={
                                                        <Delete fill={"#f93a37"} size={18} />
                                                      }
                                                  >
                                                    删除
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>
                                          </li>
                                      ))}
                                    </ol>
                                  </div>
                              )
                      )}
                    </div>
                  </div>

                  <div className="sidebar-bottom">
                    <button
                        className="bottom-group"
                        onClick={handleUpgradeClick}
                    >
                  <span className="justify-center">
                    <div className="bottom-content">
                      <div className="upgrade-logo">
                        <Button
                            className="logo svg"
                            size="sm"
                            isIconOnly
                            color="white"
                            variant="flat"
                            aria-label="upgrade"
                        >
                          <UpgradeLogo fill={"#0d0d0d"} size={16} />
                        </Button>
                      </div>
                      <div className="bottom-text">
                        <span>升级套餐</span>
                        <span className="small-text">
                          获享 GPT-4、DALL·E 以及更多功能
                        </span>
                      </div>
                    </div>
                  </span>
                    </button>
                  </div>
                </div>
              </div>
          )}
        </div>

        {/* Render the Plan component as a full-page overlay when isPlanOpen is true */}
        {isPlanOpen && <Plan onClose={handleClosePlan} />}
      </>
  );
}

export default Siderbar;
