import React, { useState } from 'react';
import './OrderManagement.css';
import { HiOutlineX } from "react-icons/hi";
import TransactionDetails from '../OrderManagement/TransactionDetails/TransactionDetails';
import OrderDetails from '../OrderManagement/OrderDetails/OrderDetails';

const OrderManagement = ({ onClose }) => {
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState(false);
    const [isTransactionDetailsOpen, setIsTransactionDetailsOpen] = useState(false);

    const membership = {
        status: 'Active',
        validUntil: '2024-08-03'
    };

    const orders = [
        { id: 1, date: '2024-07-04', amount: 22.00, status: 'Paid', transactionId: 101 },
        { id: 2, date: '2024-06-04', amount: 22.00, status: 'Paid', transactionId: 102 },
        { id: 3, date: '2024-05-04', amount: 22.00, status: 'Paid', transactionId: 103 }
    ];

    const openOrderDetails = (orderId) => {
        setSelectedOrderId(orderId);
        setIsOrderDetailsOpen(true);
        setIsTransactionDetailsOpen(false);
    };

    const openTransactionDetails = (transactionId) => {
        setSelectedTransactionId(transactionId);
        setIsTransactionDetailsOpen(true);
        setIsOrderDetailsOpen(false);
    };

    const closeOrderDetails = () => {
        setIsOrderDetailsOpen(false);
        setSelectedOrderId(null);
    };

    const closeTransactionDetails = () => {
        setIsTransactionDetailsOpen(false);
        setSelectedTransactionId(null);
    };

    const isAnyDetailOpen = isOrderDetailsOpen || isTransactionDetailsOpen;

    return (
        <div className={`order-management-overlay darkened`}> {/* 背景变暗始终生效 */}
            <div className={`order-management ${isAnyDetailOpen ? 'moved-left' : ''}`}> {/* 视详情状态决定是否左移 */}
                <div className="plan-header">
                    <button className="close-button" onClick={onClose}>
                        <HiOutlineX className="icon close-icon" />
                    </button>
                </div>
                <div className="current-plan">
                    <h2>当前方案</h2>
                    <div className="plan-details">
                        <h3>* 订阅</h3>
                        <p>RMB￥**.00 / 每 * 个*</p>
                        <p>您的方案将于 {membership.validUntil} 续订。</p>
                    </div>
                    <button className="cancel-button">取消方案</button>
                </div>

                <hr className="divider" />

                <div className="order-history">
                    <table>
                        <thead>
                        <tr>
                            <th>编号</th>
                            <th>支付时间</th>
                            <th>支付金额</th>
                            <th>支付状态</th>
                            <th>支付条件</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orders.map((order) => (
                            <tr key={order.id}>
                                <td><button onClick={() => openOrderDetails(order.id)}>{order.id}</button></td>
                                <td>{order.date}</td>
                                <td>RMB￥{order.amount.toFixed(2)}</td>
                                <td className="status-paid" onClick={() => openTransactionDetails(order.transactionId)}>{order.status}</td>
                                <td>微信支付</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {isOrderDetailsOpen && (
                <OrderDetails orderId={selectedOrderId} onClose={closeOrderDetails} />
            )}

            {isTransactionDetailsOpen && (
                <TransactionDetails transactionId={selectedTransactionId} onClose={closeTransactionDetails} />
            )}
        </div>
    );
}

export default OrderManagement;
