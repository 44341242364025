import React from 'react';
import './ConversationInput.css'
function ConversationInput(props) {
    return (
        <div className="conversationInput-background">
            <div className="input-card">
                    <p>
                        {props.data}
                    </p>
            </div>
        </div>


    );
}

export default ConversationInput;