import React, { useEffect } from 'react';
import { HiOutlineX } from "react-icons/hi";
import './Transaction.css';

function Transaction({ plan, onClose }) {
    useEffect(() => {
        setTimeout(() => {
            document.getElementById("transactionCard").style.transform = "translateX(0)";
        }, 0);
    }, []);

    return (
        <div id="transactionCard" className="transaction-card">
            <div className="transaction-header">
                <button className="close-button" onClick={() => {
                    document.getElementById("transactionCard").style.transform = "translateX(100%)";
                    setTimeout(onClose, 500); // 延迟以等待动画完成
                }}>
                    <HiOutlineX className="icon close-icon" />
                </button>
            </div>
            <div className="transaction-content">
                <div className="payment-methods">
                    <button className="payment-button">支付方式1</button>
                    <br/>
                    <button className="payment-button">支付方式2</button>
                    <br/>
                    <button className="payment-button">支付方式3</button>
                    <br/>
                </div>
                <div className="vertical-divider"></div>
                <div className="payment-details">
                    <p>¥99</p>
                    <img src="your-qr-code-url" alt="QR Code" className="qr-code"/>
                </div>
            </div>
        </div>
    );
}

export default Transaction;
